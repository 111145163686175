
    .el-switch.is-checked .el-switch__core {
        border-color: #20D08C;
        background-color: #20D08C;
    }
    .promotion-content {
        margin-right: 20px;
        min-height: calc(100vh - 299px);
        .table-btn {
            padding-top: 10px;
        }
        /deep/ .el-table {
            min-height: calc(100vh - 447px);
            .el-table__body {
                tr td {
                    padding: 27px 0;
                }
                .cell {
                    .plan-name {
                        display: flex;
                        align-items: center;
                        /*justify-content: center;*/
                        cursor: pointer;
                        color: #1E63F1;
                        .edit-plan-iocn {
                            display: none;
                            font-size: 14px;
                            margin-left: 10px;
                        }
                        &:hover {
                            color: #1E63F1;
                            .edit-plan-iocn {
                                display: block;
                                color: #333;
                            }
                        }
                    }
                    .name-input {
                        display: block;
                        position: absolute;
                        top: 26px;
                        height: 18px;
                        width: 71px;
                    }
                    .daily-budget {
                        cursor: pointer;
                        color: #1E63F1;
                    }
                    .del-blue-btn {
                        cursor: pointer;
                        color: #1E63F1;
                    }
                }
            }
        }
    }
    .keyword-select {
        padding-top: 10px;
    }
